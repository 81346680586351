import { IResponseData } from "@/types/ResponseData";

export class Functions {
  pagingDataStr(name: string, data: IResponseData): string {
    return `Showing ${
      data.requestParams.pageSize * (data.requestParams.pageNumber - 1) + 1
    } -
      ${
        data.requestParams.pageSize * data.requestParams.pageNumber > data.count
          ? data.count
          : data.requestParams.pageSize * data.requestParams.pageNumber
      } of ${data.count} ${name}`;
  }
  formatNumber(quantity: number): string {
    return isNaN(quantity)
      ? "-"
      : quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  prepend(value: any, array: Array<any>): Array<any> {
    const newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }
  groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);
}
