
import { Component, Vue } from "vue-property-decorator";
import { UserService } from "@/services/user-service";
import { IRole, IUser } from "@/types/User";
import newUserModal from "@/components/UserModal.vue";
import confirmDeleteModal from "@/components/ConfirmDeleteModal.vue";
import editUserModal from "@/components/EditUserModal.vue";
import converterModal from "@/components/ConverterModal.vue";
import {Functions} from "@/helpers/Functions";
import {IUnit} from "@/types/Unit";
import {UnitService} from "@/services/unit-service";
@Component({
  name: "Settings",
  components: {
    newUserModal,
    confirmDeleteModal,
    editUserModal,
    converterModal,
  },
})
export default class Settings extends Vue {
  show = { showOverlay: false }; //this is for new user modal
  confirmDelete = { showOverlay: false };
  converter = { showOverlay: false };
  loading = true;
  sLoading = true;
  functions = new Functions();
  userService = new UserService();
  unitService = new UnitService();
  userString = localStorage.getItem("user");
  isAdmin = false;
  allUsers: IUser[] = [];
  myUsers: IUser[] = [];
  role = "";
  roles: IRole[] = [];
  userRoles: any[] = [];
  userFilters = {
    username: "",
    email: "",
    role: "",
  };
  checkIfAdmin(): void {
    if (this.userString != null) {
      const user = JSON.parse(this.userString);
      const userRoles = user.roles;
      if (userRoles.includes("admin")) {
        this.isAdmin = true;
      }
    }
  }
  async initialize() {
    let userResponse = await this.userService.getAll();
    this.allUsers = userResponse.data;
    this.myUsers = this.allUsers;
    this.roles = await this.userService.getRoles();
    console.log(this.roles);
    this.role = "user";
  }
  async beforeMount(): Promise<void> {
    this.checkIfAdmin();
    await this.initialize();
    this.loading = false;
    this.sLoading = false;
  }
  async saveUnit(unit: IUnit): Promise<void> {
    this.converter = { showOverlay: true };
    let result = await this.unitService.create(unit);
    let message = result.message;
    let title = "Unit Saved";
    let variant = "danger";
    if (result.isSuccess) {
      this.$bvModal.hide("ConverterModal");
      variant = "success";
    }
    this.converter = { showOverlay: false };
    this.makeToast(message, title, variant);
  }
  clearFilters(): void {
    this.myUsers = this.allUsers;
    this.userFilters = {
      username: "",
      email: "",
      role: "",
    };
  }
  filterUsers(): void {
    const allUsers = this.allUsers;
    const myFilters = this.userFilters;
    this.myUsers = allUsers.filter(function (selectedUser) {
      let toFilter = true;
      if (myFilters.username != "") {
        toFilter = toFilter && selectedUser.userName == myFilters.username;
      }
      if (myFilters.email != "") {
        toFilter = toFilter && selectedUser.email == myFilters.email;
      }
      if (myFilters.role != "") {
        selectedUser.roles = selectedUser.roles || [""];
        toFilter = toFilter && selectedUser.roles[0] == myFilters.role;
      }
      if (toFilter) {
        return selectedUser;
      }
    });
  }
  async editUser(user: IUser): Promise<void> {
    user.email = user.email?.toLowerCase();
    let result = await this.userService.edit(user);
    let message = result.message;
    let title = "Editing User " + user.userName;
    let variant = "danger";
    if (result.isSuccess) {
      this.$bvModal.hide("createUserModal");
      this.myUsers = this.functions.prepend(result.data, this.myUsers);
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.makeToast(message, title, variant);
  }
  async submitNewUser(user: IUser): Promise<void> {
    user.email = user.email?.toLowerCase();
    let result = await this.userService.create(user);
    let message = result.message;
    let title = "Creating a new User";
    let variant = "danger";
    if (result.isSuccess) {
      this.$bvModal.hide(this.editUserModalId(user.id).name);
      variant = "success";
    }
    this.show = { showOverlay: false };
    this.makeToast(message, title, variant);
  }
  editUserModalId(i: string): {
    id: string;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewLoadingPlanModal" + i,
      showOverlay: false,
    };
  }
  async deleteUser(id: string): Promise<void> {
    if (this.isAdmin) {
      this.confirmDelete.showOverlay = true;
      const result = await this.userService.delete(id);
      this.confirmDelete.showOverlay = false;
      this.$bvModal.hide(this.deleteUserModalId(id).name);
      let message = result.message;
      let title = "Deleting User";
      let variant = "danger";
      if (result.isSuccess) {
        variant = "success";
      }
      this.makeToast(message, title, variant);
    } else {
      this.$bvModal.hide(this.deleteUserModalId(id).name);
      let message = "You can't update this resource!";
      let title = "Deleting User";
      let variant = "danger";
      this.makeToast(message, title, variant);
    }
  }
  deleteUserModalId(i: string): {
    id: string;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "deleteLoadingPlanModal" + i,
      showOverlay: false,
    };
  }
  closeModal(id: string): void {
    const modal = this.deleteUserModalId(id);
    this.$bvModal.hide(modal.name);
  }
  makeToast(
    message = "Oh Snap! something went wrong. Please try again later.",
    title = "",
    variant = "danger"
  ): void {
    this.$bvToast.toast(message, {
      title: title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant,
    });
  }
}
