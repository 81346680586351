import axios from "axios";
import { IUnit } from "@/types/Unit";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *Unit service
 * Provides UI business logic for unit
 */
export class UnitService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IUnit[]> {
    //console.log("getUnit:", this.API_URL);
    const result = await axios.get(`${this.API_URL}/Unit`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
  public async create(unit: IUnit): Promise<IServiceResponse> {
    try {
      const result = await axios.post(`${this.API_URL}/Unit`, unit, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
