
import { Component, Vue } from "vue-property-decorator";
import { Defaults } from "@/helpers/Defaults";
import { Functions } from "@/helpers/Functions";
@Component({
  name: "ConverterModal",
  components: {},
  props: {
    converter: {},
  },
})
export default class converterModal extends Vue {
  unit = new Defaults().unit;
  functions = new Functions();
  get conversionStatement(): string {
    return this.unit.first != "" && this.unit.second != ""
      ? `Are you sure 1${this.unit.first} = ${this.functions.formatNumber(
          this.unit.value
        )}  ${this.unit.second}?`
      : "";
  }
  save(): void {
    this.$emit("save:unit", this.unit);
  }
}
